import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { useControl } from 'react-map-gl'
import { useValue } from '../../../context/ContextProvider'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'


const GeoCoder = () => {
    const { dispatch } = useValue()
    const ctrl = new MapboxGeocoder({
        accessToken: process.env.REACT_APP_MAP_TOKEN,
        marker: false,
        collapsed: true
    })
    useControl(() => ctrl)
    //setting an listener on the object
    ctrl.on('result', (e) => {
        const coord = e.result.geometry.coordinates
        dispatch({
            type: 'UPDATE_LOCATION',
            payload: { lng: coord[0], lat: coord[1] }
        })
    })
    return null
}

export default GeoCoder