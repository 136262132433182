import { Blocks } from 'react-loader-spinner'

const Loader = () => {
    return (
        <>
            <Blocks
                height="50vh"
                width="50wh"
                color="#1349c7"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                visible={true}
            />
        </>
    )
}

export default Loader