import { FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Stack, TextField } from '@mui/material'
import Select from '@mui/material/Select'
import React, { useState, useEffect } from 'react'
import { useValue } from '../../../context/ContextProvider';
import InfoField from './InfoField';

const AddDetails = () => {

    //--------------------------TESTING--------------------------------------


    return (
        <>
            <Stack
                sx={{
                    alignItems: 'center',
                    '& .MuiTextField-root': { width: '100%', maxWidth: 500, m: 1 },
                }}
            >

                <InfoField />
                {/* <InfoField
                    mainProps={
                        { name: 'pStage', label: 'Project Stage', value: pStage }
                    }
                    minLength={3}
                />
                <InfoField
                    mainProps={
                        { name: 'pEst', label: 'Project Estimation', value: pEst }
                    }
                    minLength={3}
                />
                <InfoField
                    mainProps={
                        { name: 'bName', label: 'Builder', value: bName }
                    }
                    minLength={3}
                />
                <InfoField
                    mainProps={
                        { name: 'bContact', label: 'Builder Contact', value: bContact }
                    }
                />
                <InfoField
                    mainProps={
                        { name: 'aContact', label: 'Applicant', value: aContact }
                    }
                /> */}
            </Stack>
        </>
    )
}

export default AddDetails