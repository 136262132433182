import { Cancel, Send } from '@mui/icons-material';
import { Box, Button, Container, Stack, Step, StepButton, Stepper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createProject } from '../../actions/project';
import { useValue } from '../../context/ContextProvider';
import AddDetails from './addDetails/AddDetails';
import AddImages from './addImages/AddImages';
import AddLocation from './addLocation/AddLocation';

const AddProjects = ({ setPage }) => {
    //Getting the context
    const { state: { images, details, location, currentUser }, dispatch } = useValue()

    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([
        { label: 'Location', completed: false },
        { label: 'Details', completed: false },
        { label: 'Images', completed: true },
    ]);
    const [showSubmit, setShowSubmit] = useState(false);

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((activeStep) => activeStep + 1);
        } else {
            const stepIndex = findUnfinished();
            setActiveStep(stepIndex);
        }
    };

    const checkDisabled = () => {
        if (activeStep < steps.length - 1) return false;
        const index = findUnfinished();
        if (index !== -1) return false;
        return true;
    };

    const findUnfinished = () => {
        return steps.findIndex((step) => !step.completed);
    };

    //Showing the button
    useEffect(() => {
        if (findUnfinished() === -1) {
            if (!showSubmit) setShowSubmit(true)
        } else {
            if (showSubmit) setShowSubmit(false)
        }
    }, [steps])

    // useEffect(() => {
    //     if (images.length) {
    //         //images there and completed is false
    //         if (!steps[2].completed) setComplete(2, true)
    //     } else {
    //         if (steps[2].completed) setComplete(2, false)
    //     }
    // }, [images])
    useEffect(() => {
        if (details.pType && details.pStage && details.pEst) {
            if (!steps[1].completed) setComplete(1, true)
        } else {
            if (steps[1].completed) setComplete(1, false)
        }
    }, [details])
    useEffect(() => {
        if (location.lat || location.lon) {
            if (!steps[0].completed) setComplete(0, true)
        } else {
            if (steps[0].completed) setComplete(0, false)
        }
    }, [location])

    const setComplete = (index, status) => {
        setSteps((steps) => {
            steps[index].completed = status
            //need to spread the changes in React
            return [...steps]
        })
    }

    //Dispatching the data from client to server
    const handleSubmit = () => {
        const project = {
            lng: location.lng,
            lat: location.lat,
            pName: details.pName,
            pType: details.pType,
            pStage: details.pStage,
            pEst: details.pEst,
            pDes: details.pDes,
            aName: details.aName,
            aContact: details.aContact,
            bName: details.bName,
            bContact: details.bContact,
            bEmail: details.bEmail,
            bWeb: details.bWeb,
            images,
        }
        createProject(project, currentUser, dispatch, setPage)
    };

    const handleCancel = () => {

    }

    return (
        //Container makes the center
        <Container sx={{ my: 4 }}>
            <Stepper
                alternativeLabel
                nonLinear
                activeStep={activeStep}
                sx={{ mb: 3 }}
            >
                {steps.map((step, index) => (
                    <Step key={step.label} completed={step.completed}>
                        <StepButton onClick={() => setActiveStep(index)}>
                            {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <Box>
                {
                    {
                        0: <AddLocation />,
                        1: <AddDetails />,
                        2: <AddImages />,
                    }[activeStep]
                }
            </Box>
            {/* Step Controller */}
            <Box sx={{ pb: 7 }}>
                <Stack
                    direction='row'
                    sx={{ pt: 2, justifyContent: 'space-around' }}
                >
                    {/* GO BACK <<<< */}
                    <Button
                        color='inherit'
                        disabled={!activeStep}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Back
                    </Button>
                    {/* GO NEXT >>> */}
                    <Button
                        color='inherit'
                        disabled={checkDisabled()}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </Stack>
                <Stack
                    sx={{ alignItems: 'center', justifyContent: 'center', gap: 2 }}
                    direction="row"
                >
                    {showSubmit && (
                        <Button
                            variant="contained"
                            endIcon={<Send />}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        endIcon={<Cancel />}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Container>
    )
}

export default AddProjects