import React from 'react'
import { useValue } from '../../context/ContextProvider'
import { AppBar, Box, Dialog, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import Loading from '../Loading'

const ProjectDetail = () => {
    const { state: { project }, dispatch } = useValue()
    console.log("🚀 ~ ProjectDetail ~ project:", project)
    const handleClose = () => {
        dispatch({ type: 'UPDATE_PROJECT', payload: null });
    };

    //Segment style
    const commonStyles = {
        boxShadow: 3,
        p: 2,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
    };

    return (
        <>
            {project ? (<Dialog
                fullScreen
                open={Boolean(project)}
                onClose={handleClose}
            // TransitionComponent={Transition}
            >
                <AppBar position="relative">
                    <Toolbar>
                        <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
                            {project.pName}
                        </Typography>
                        <IconButton color="inherit" onClick={handleClose} >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{ ...commonStyles }}>
                    <Grid container spacing={2} >
                        <Grid item xs={6} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1" component="div" >
                                        dfsdf
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        fsdf
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Address: 1030114
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Stage:fds
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Applicant: dfs
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Contractor:dfs
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>) : (<Loading />)}
        </>
    )
}

export default ProjectDetail