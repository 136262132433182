import React, { useEffect, useState } from 'react'
import './cluster.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import ReactMapGL, { Marker } from 'react-map-gl'
import Supercluster from 'supercluster';

import { getProjects } from '../../actions/project'
import { useValue } from '../../context/ContextProvider'
import { Avatar, Box, Container, Paper, Tooltip } from '@mui/material'
import GeocoderInput from '../sidebar/GeocoderInput';
import ProjectOverview from './ProjectOverview';
import { Link } from 'react-router-dom';

//create the cluster object
const supercluster = new Supercluster({
    radius: 75,
    maxZoom: 20
})

const ClusterMap = () => {
    const { state: { projects }, dispatch, mapRef } = useValue()

    //cluster states
    const [points, setPoints] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [bounds, setBounds] = useState([-180, -85, 180, 85]);
    const [zoom, setZoom] = useState(0);
    //child compo
    const [proOverview, setProOverview] = useState(null);

    useEffect(() => {
        getProjects(dispatch)
    }, [])

    //Format for the Supercluster
    useEffect(() => {
        //* Creating an array for the supper cluster 
        const points = projects.map(p => ({
            type: 'Feature',
            properties: {
                cluster: false,
                projectId: p._id,
                pName: p.pName,
                pType: p.pType,
                pStage: p.pStage,
                pEst: p.pEst,
                aName: p.aType,
                description: p.description,
                lng: p.lng,
                lat: p.lat,
                images: p.images,
                uPhoto: p.uPhoto,
                uName: p.uName,
            },
            geometry: {
                type: 'Point',
                coordinates: [parseFloat(p.lng), parseFloat(p.lat)],
            },
        }))
        setPoints(points)
    }, [projects])

    //Creating the cluster
    useEffect(() => {
        supercluster.load(points)
        setClusters(supercluster.getClusters(bounds, zoom))
    }, [points, zoom, bounds])

    //Extacting the exact bounds of the map
    useEffect(() => {
        if (mapRef.current) {
            setBounds(mapRef.current.getMap().getBounds().toArray().flat())
        }
    }, [mapRef?.current])

    return (
        <Box
            sx={{
                height: 1000,
                position: 'relative'
            }}>
            <ReactMapGL
                initialViewState={{ latitude: 49.100376, longitude: -122.673058, zoom: 7 }}
                mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                ref={mapRef}
                onZoomEnd={(e) => setZoom(Math.round(e.viewState.zoom))}
            >
                {clusters.map((cluster) => {
                    const { cluster: isCluster, point_count } = cluster.properties;
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${cluster.id}`}
                                longitude={longitude}
                                latitude={latitude}
                            >
                                {/* cluster icon */}
                                <div
                                    className="cluster-marker"
                                    style={{
                                        width: `${10 + (point_count / points.length) * 20}px`,
                                        height: `${10 + (point_count / points.length) * 20}px`,
                                    }}
                                    onClick={() => {
                                        const zoom = Math.min(
                                            supercluster.getClusterExpansionZoom(cluster.id),
                                            20
                                        );
                                        mapRef.current.flyTo({
                                            center: [longitude, latitude],
                                            zoom,
                                            speed: 1,
                                        });
                                    }}
                                >
                                    {point_count}
                                </div>
                            </Marker>
                        );
                    }

                    return (
                        <Marker
                            key={`room-${cluster.properties.projectId}`}
                            longitude={longitude}
                            latitude={latitude}
                        >
                            <Tooltip title={cluster.properties.pName}>
                                {/* <LocationOnIcon /> */}
                                {/* <Avatar
                                    src={'https://i.pravatar.cc/150?img=3'}
                                    component={Paper}
                                    elevation={2}
                                // onClick={() => setPopupInfo(cluster.properties)}
                                /> */}
                                <a href={`/project/${cluster.properties.projectId}`}>
                                    <LocationOnIcon
                                        color={'error'}
                                        fontSize={'large'}
                                        elevation={6}
                                    />
                                </a>
                            </Tooltip>
                        </Marker>
                    );
                })}
                <GeocoderInput />
                {/* This is model Overview  */}
                {proOverview && (
                    <ProjectOverview proOverview={proOverview} setProOverview={setProOverview} />
                )}
            </ReactMapGL>
        </Box>
    );
}

export default ClusterMap