import { Box, Grid, Modal, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getProEst } from '../functions/GlobalFunctions';

const ProjectOverview = ({ proOverview, setProOverview }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        height: '70%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = useState(Boolean(proOverview.projectId));
    const [address, setAddress] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setProOverview(false);

    useEffect(() => {
        if (proOverview) {
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${proOverview.lng},${proOverview.lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`
            fetch(url)
                .then((response) => response.json())
                .then((data) => setAddress(data.features[0]));
        }
    }, [proOverview]);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2} >
                        <Grid item sm={6} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        {proOverview.pName}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <b>Project Type:</b> {proOverview.pType}
                                    </Typography>
                                    <Typography variant="body2" color="text.primary">
                                        <b>Address:</b> {address?.place_name}
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <b>Stage:</b> {proOverview.pStage}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <b>Est:</b> ${getProEst(proOverview.pEst)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={6} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>

                                    <Typography variant="body2" gutterBottom>
                                        <b>Contractor:</b> {proOverview.bName}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <b> Cont. Contact:</b> {proOverview.bContact}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <b>Cont. Email:</b> {proOverview.bEmail}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <b>Cont. Web:</b> <a href={`${proOverview.bWeb}`} target='_blank'>fdfd</a>
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div">
                                        <b>Applicant:</b> {proOverview.aName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item sm={12} xs={12}>

                                {(proOverview.images[0]) ? (<img src={`${proOverview.images[0]}`} style={{ maxHeight: "40vh", maxWidth: "100%" }} />) : (<img src='/assets/img/bg.jpg' style={{ maxHeight: "50vh", maxWidth: "100%" }} />)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default ProjectOverview