export function getProEst(val) {
    switch (val) {
        case 1:
            return '100K'
            break;
        case 2:
            return '200k - 400k'
            break;
        case 3:
            return '500k - 900k'
            break;
        case 4:
            return '1M - 1.5M'
            break;
        case 5:
            return '2M+'
            break;
        case 6:
            return '5M+'
            break;
        case 7:
            return '10M+'
            break;
        case 8:
            return '20M+'
            break;
        case 9:
            return '50M'
            break;

        default:
            break;
    }
}