import React, { useState } from 'react'
import { useValue } from '../context/ContextProvider'
import { AppBar, Box, Button, Container, IconButton, Toolbar, Typography } from '@mui/material'
import { Lock, Menu } from '@mui/icons-material'
import UserIcons from './user/UserIcons'
import Sidebar from './sidebar/Sidebar'

const NavBar = () => {
    const {
        state: { currentUser },
        dispatch
    } = useValue()
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <AppBar>
                <Container maxWidth='lg'>
                    <Toolbar disableGutters={true}>
                        <Box sx={{ mr: 1 }}>
                            <IconButton size='large' color='inherit'
                                onClick={() => setIsOpen(true)}
                            >
                                <Menu />
                            </IconButton>
                        </Box>
                        {/* For the big screen */}
                        <Typography
                            variant='h6'
                            component='h1'
                            noWrap
                            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                        >
                            Project Listing
                        </Typography>
                        {/* For mobile view */}
                        <Typography
                            variant='h6'
                            component='h1'
                            noWrap
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            Project Listing
                        </Typography>
                        {
                            !currentUser ? (
                                <Button
                                    color='inherit'
                                    startIcon={<Lock />}
                                    onClick={() => dispatch({ type: 'OPEN_LOGIN' })}
                                >
                                    Login
                                </Button>
                            ) : (
                                <UserIcons />
                            )
                        }
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar />
            <Sidebar{...{ isOpen, setIsOpen }} />
        </>
    )
}

export default NavBar