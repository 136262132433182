import React from 'react'
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'
import Home from './pages/Home'
import ProjectDetail from './components/Projects/ProjectDetail'
import Project from './pages/Project'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/project/:id' element={<Project />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App