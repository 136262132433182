import { Mail, Notifications, TripOriginOutlined } from '@mui/icons-material'
import { Avatar, Badge, Box, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useValue } from '../../context/ContextProvider'
import useCheckToken from '../../hooks/useCheckToken'
import UserMenu from './UserMenu'

const UserIcons = () => {

    useCheckToken()

    const [anchorUserMenu, setAnchorUserMenu] = useState(null);

    const { state: { currentUser } } = useValue()

    // console.log(anchorUserMenu)
    return (
        <Box>
            {/* <IconButton size='large' color='inherit'>
                <Badge color='error' badgeContent={5}>
                    <Mail />
                </Badge>
            </IconButton>
            <IconButton size='large' color='inherit'>
                <Badge color='error' badgeContent={12}>
                    <Notifications />
                </Badge>
            </IconButton> */}
            {/* Hover Function */}
            <Tooltip title="View all Projects">
                <IconButton onClick={(e) => setAnchorUserMenu(e.currentTarget)}>
                    <Avatar src={currentUser?.img} alt={currentUser?.name}>
                        {currentUser?.name?.charAt(0).toUpperCase()}
                    </Avatar>
                </IconButton>
            </Tooltip>
            {/* Passing the prop to the menu */}
            <UserMenu {...{ anchorUserMenu, setAnchorUserMenu }} />
        </Box>
    )
}

export default UserIcons