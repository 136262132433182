import fetchData from "./utils/fetchData"
import axios from 'axios';


const url = process.env.REACT_APP_SERVER_URL + '/project'

export const createProject = async (project, currentUser, dispatch, setPage) => {
    console.log(project)
    const result = await fetchData({ url, body: project, token: currentUser?.token }, dispatch)
    if (result) {
        dispatch(
            {
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'success',
                    message: 'New project has been added'
                }
            }
        )
        //resetting the values
        dispatch({ type: 'RESET_PROJECT_DETAIL' })
        setPage(0)
    }
    dispatch({ type: 'END_LOADING' })
}

export const getProjects = async (dispatch) => {
    const result = await fetchData({ url, method: 'GET' }, dispatch)
    if (result) {
        dispatch({
            type: 'UPDATE_PROJECTS', payload: result
        })
    }
}

export const getProById = async (dispatch, id) => {
    dispatch({
        type: 'START_LOADING'
    })
    const route = `${url}/${id}`
    const result = await axios.get(route)
    if (result) {
        dispatch({
            type: 'UPDATE_PROJECT_BY_ID', payload: result.data.result, loading: false
        })
    }
}