import { useValue } from '../../../context/ContextProvider';
import { Avatar, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import pendingIcon from './icons/progress1.svg';
import { Check } from '@mui/icons-material';

let timer;
const InfoField = ({ mainProps, optionalProps = {}, minLength }) => {

    const {
        state: {
            details: {
                pName,
                pType,
                pStage,
                pEst,
                pDes,
                aName,
                aContact,
                bName,
                bContact,
                bEmail,
                bWeb
            }
        },
        dispatch
    } = useValue()

    // const { dispatch } = useValue();
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [proName, setProName] = useState(pName);
    const [type, setType] = useState(pType)
    const [stage, setStage] = useState(pStage)
    const [est, setEst] = useState(pEst);
    const [des, setDes] = useState(pDes);
    const [appName, setAppName] = useState(aName);
    const [appContact, setAppContact] = useState(aContact);
    const [builderName, setBuilderName] = useState(bName)
    const [builderContact, setBuilderContact] = useState(bContact);
    const [builderEmail, setBuilderEmail] = useState(bEmail);
    const [builderWeb, setBuilderWeb] = useState(bWeb);

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: 'UPDATE_DETAILS',
            payload: {
                pName: proName,
                pType: type,
                pStage: stage,
                pEst: est,
                pDes: des,
                aName: appName,
                aContact: appContact,
                bName: builderName,
                bContact: builderContact,
                bEmail: builderEmail,
                bWeb: builderWeb,
            },
        })
    };


    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid sx={{ width: '100%', maxWidth: 500, m: 1 }}>
                    <TextField
                        minLength={3}
                        name="pName"
                        label="Project Name"
                        value={proName}
                        error={error}
                        helperText={error && `This field must be ${3} characters or more`}
                        color={success ? 'success' : 'primary'}
                        variant="outlined"
                        onChange={(e) => setProName(e.target.value)}
                        required
                    />
                    <FormControl sx={{ width: '100%', maxWidth: 500, m: 1 }} >
                        <InputLabel id="demo-simple-select-label">Project Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Project Type"
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value={"Residential"}>Residential</MenuItem>
                            <MenuItem value={"Industrial"}>Industrial</MenuItem>
                            <MenuItem value={"Commercial"}>Commercial</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: '100%', maxWidth: 500, m: 1 }}>
                        <InputLabel id="demo-simple-select-label">Project Stage</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={stage}
                            label="Project Estimation"
                            onChange={(e) => setStage(e.target.value)}
                        >
                            <MenuItem value={"Pre-development"}> Pre-development</MenuItem>
                            <MenuItem value={"Foundation"}> Foundation</MenuItem>
                            <MenuItem value={"Framing"}>Framing</MenuItem>
                            <MenuItem value={"Touch up"}>Touch up</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: '100%', maxWidth: 500, m: 1 }}>
                        <InputLabel id="demo-simple-select-label">Project Estimation</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={est}
                            label="Project Estimation"
                            onChange={(e) => setEst(e.target.value)}
                        >
                            <MenuItem value={1}> 100K</MenuItem>
                            <MenuItem value={2}>200k - 400k</MenuItem>
                            <MenuItem value={3}>500k - 900k</MenuItem>
                            <MenuItem value={4}>1M - 1.5M</MenuItem>
                            <MenuItem value={5}>2M+</MenuItem>
                            <MenuItem value={6}>5M+</MenuItem>
                            <MenuItem value={7}>10M+</MenuItem>
                            <MenuItem value={8}>20M+</MenuItem>
                            <MenuItem value={9}>50M+</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        name="bName"
                        label="Applicant Name"
                        value={appName}
                        error={error}
                        helperText={error && `This field must be ${3} characters or more`}
                        color={success ? 'success' : 'primary'}
                        variant="outlined"
                        onChange={(e) => setAppName(e.target.value)}
                    />
                    <TextField
                        name="bName"
                        label="Applicant Contact"
                        value={appContact}
                        error={error}
                        helperText={error && `This field must be ${3} characters or more`}
                        color={success ? 'success' : 'primary'}
                        variant="outlined"
                        onChange={(e) => setAppContact(e.target.value)}
                    />

                    <TextField
                        name="bName"
                        label="Builder Name"
                        value={builderName}
                        error={error}
                        helperText={error && `This field must be ${3} characters or more`}
                        color={success ? 'success' : 'primary'}
                        variant="outlined"
                        onChange={(e) => setBuilderName(e.target.value)}
                    />
                    <TextField
                        name="bName"
                        label="Builder Contact"
                        value={builderContact}
                        error={error}
                        helperText={error && `This field must be ${3} characters or more`}
                        color={success ? 'success' : 'primary'}
                        variant="outlined"
                        onChange={(e) => setBuilderContact(e.target.value)}
                    />
                    <TextField
                        name="bEmail"
                        label="Builder Email"
                        value={builderEmail}
                        error={error}
                        helperText={error && `This field must be ${3} characters or more`}
                        color={success ? 'success' : 'primary'}
                        variant="outlined"
                        onChange={(e) => setBuilderEmail(e.target.value)}
                    />
                    <TextField
                        name="bWeb"
                        label="Builder Website"
                        value={builderWeb}
                        error={error}
                        helperText={error && `This field must be ${3} characters or more`}
                        color={success ? 'success' : 'primary'}
                        variant="outlined"
                        onChange={(e) => setBuilderWeb(e.target.value)}
                    />
                    <textarea name="pDes" placeholder="Project discription..."
                        variant="outlined" rows="5" cols="10"
                        onChange={(e) => setDes(e.target.value)}
                    />



                </Grid>
                <Grid>
                    <Button variant="contained" type="submit" onSubmit={handleSubmit}>Submit</Button>
                </Grid>
            </form>
        </>
    );
};

export default InfoField;