import React, { useEffect, useRef } from 'react'
import ReactMapGL, { GeolocateControl, Marker, NavigationControl } from 'react-map-gl'
import { Box } from '@mui/material'
import { useValue } from '../../../context/ContextProvider'
import 'mapbox-gl/dist/mapbox-gl.css'
import GeoCoder from './GeoCoder'

const AddLocation = () => {
    const { state: { location: { lng, lat } }, dispatch } = useValue()

    const mapRef = useRef()

    useEffect(() => {
        if (!lng & !lat) {
            fetch('https://ipapi.co/json')
                .then(response => {
                    return response.json()
                }).then(data => {
                    //setting the map center
                    mapRef.current.flyTo({
                        center: [data.longitude, data.latitude]
                    })
                    // updating the state location
                    dispatch({
                        type: 'UPDATE_LOCATION', payload: {
                            lng: data.longitude,
                            lat: data.latitude
                        }
                    })
                })
        }
    }, []);

    return (
        <Box
            sx={{
                height: 400,
                postion: 'relative'
            }}>
            <ReactMapGL
                ref={mapRef}
                mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
                initialViewState={{
                    longitude: lng,
                    latitude: lat,
                    zoom: 11
                }}
                mapStyle='mapbox://styles/mapbox/streets-v11'
            >
                <Marker
                    latitude={lat}
                    longitude={lng}
                    draggable
                />
                <NavigationControl position='bottom-right' />
                <GeolocateControl
                    position='top-left'
                    //button that enables the locator
                    trackUserLocation
                    onGeolocate={(e) =>
                        dispatch({
                            type: 'UPDATE_LOCATION',
                            payload: {
                                lng: e.coords.longitude,
                                lat: e.coords.latitude
                            }
                        })
                    }
                />
                <GeoCoder />
            </ReactMapGL>

        </Box>
    )
}

export default AddLocation