import React from 'react'
import Loading from '../components/Loading'
import Notification from '../components/user/Notification'
import Login from '../components/user/Login'
import NavBar from '../components/NavBar'
import BottomNav from '../components/BottomNav'
import ProjectDetail from '../components/Projects/ProjectDetail'

const Home = () => {
    return (
        <>
            <Loading />
            <Notification />
            <Login />
            <NavBar />
            <BottomNav />
            <ProjectDetail />
        </>
    )
}

export default Home