import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Paper,
} from '@mui/material';
import { AddLocationAlt, Handyman, LocationOn } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import ClusterMap from './map/ClusterMap';
import Projects from './Projects/Projects';
import AddProjects from './addProjects/AddProjects';
import Protected from './protected/Protected';


const BottomNav = () => {
    const [value, setValue] = useState(0);
    const ref = useRef()

    useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0
    }, [value])

    // console.log(ref)

    return (
        <Box ref={ref}>
            {/* Switch */}
            {{
                0: <ClusterMap />,
                1: <Projects />,
                2: <Protected><AddProjects setPage={setValue} /></Protected>
            }[value]}
            <Paper
                elevation={3}
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }}
            >
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(e, newValue) => setValue(newValue)}
                >
                    <BottomNavigationAction label="Map" icon={<LocationOn />} />
                    <BottomNavigationAction label="Projects" icon={<Handyman />} />
                    <BottomNavigationAction label="Add" icon={<AddLocationAlt />} />
                </BottomNavigation>
            </Paper>
        </Box>
    );
};

export default BottomNav;