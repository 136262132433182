import { createContext, useContext, useEffect, useReducer, useRef } from 'react'
import reducer from './reducer'

const initialState = {
    currentUser: null,
    openLogin: false,
    loading: false,
    alert: { open: false, severity: 'info', message: '' },
    images: [],
    details: {
        pName: '',
        pType: '',
        pStage: '',
        pEst: '',
        pDes: '',
        aName: '',
        aContact: '',
        bName: '',
        bContact: '',
        bEmail: '',
        bWeb: ''

    },
    location: { lng: 0, lat: 0 },
    projects: [],
    priceFilter: 50,
    addressFilter: null
};

const Context = createContext(initialState);

export const useValue = () => {
    return useContext(Context);
};

const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    //* Making mapRef to a global state
    const mapRef = useRef()
    const containerRef = useRef()
    //getting currentUser form local storage
    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'))
        if (currentUser) {
            dispatch({ type: 'UPDATE_USER', payload: currentUser })
        }
    }, [])

    return (
        <Context.Provider value={{ state, dispatch, mapRef, containerRef }}>
            {children}
        </Context.Provider>
    );
};

export default ContextProvider