import { Box, ButtonBase, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useValue } from '../../context/ContextProvider';
import { getProEst } from '../functions/GlobalFunctions';

const Projects = () => {
    const { state: { projects }, dispatch } = useValue()
    //Segment style
    const commonStyles = {
        boxShadow: 3,
        p: 2,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
    };

    return (
        <Container>
            {projects.map((pro) => (
                <a href={`/project/${pro._id}`} style={{ textDecoration: 'none', color: "black" }}>
                    <Box sx={{ ...commonStyles }}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle1" component="div" onClick={() => console.log("Hello man")} >
                                            {pro.pName}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {pro.pType}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Est: ${getProEst(pro.pEst)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Stage:</b> {pro.pStage}
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div">
                                            <b>Applicant:</b> {pro.bName}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Contractor:</b> {pro.bName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </a>
            ))
            }
        </Container >
    )
}

export default Projects