// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIR_API_KEY,
    authDomain: process.env.REACT_APP_FIR_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIR_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIR_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIR_SENDER_ID,
    appId: process.env.REACT_APP_FIR_APP_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();