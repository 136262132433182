import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useValue } from '../context/ContextProvider'
import { getProById } from '../actions/project'
import { AppBar, Box, Grid, Toolbar, Typography } from '@mui/material'
import Loader from '../components/ui/Loader'
import { useState } from 'react'
import { getProEst } from '../components/functions/GlobalFunctions'


const Project = () => {
    const { id } = useParams()
    const { state: { projectByID: pro, loading }, dispatch, mapRef } = useValue()

    const [address, setAddress] = useState(null);
    const [currentURL, setCurrentURL] = useState("");

    useEffect(() => {
        getProById(dispatch, id)
        setCurrentURL(window.location.href)
    }, [id]);

    useEffect(() => {
        if (pro) {
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${pro.lng},${pro.lat}.json?access_token=${process.env.REACT_APP_MAP_TOKEN}`
            fetch(url)
                .then((response) => response.json())
                .then((data) => setAddress(data.features[0]));
        }
    }, [pro]);

    //Segment style
    const commonStyles = {
        boxShadow: 3,
        p: 2,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
    };

    return (
        <>
            {!pro ? (<Loader />) : (
                <>
                    <Helmet>
                        <meta charset="UTF-8" />
                        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <meta name="format-detection" content="telephone=no" />
                        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                        <link rel="canonical" href={currentURL} />
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:type" content="website" />

                        <title>{pro.pName} </title>
                        <meta name="description" content={pro.pDes} />
                        <meta name="keywords" content="nearest projects, projects bids, construction project, construction project vancouver, greater vancouver project, construction sites, bidding website" />
                        <meta property="og:title" content={pro.pName} />
                        <meta property="og:description"
                            content={pro.pDes} />
                        <meta property="og:type" content="Website" />
                        <meta property="og:url" content={currentURL} />
                        <meta property="og:image" content={pro.images[0]} />
                        <meta property="og:image:width" content="1280" />
                        <meta property="og:image:height" content="720" />

                        <meta name="twitter:title" content={pro.pName} />
                        <meta name="twitter:description"
                            content={pro.pDes} />
                        <meta name="twitter:image" content={pro.images[0]} />
                        <meta name="twitter:card" content="Website" />

                        <meta property="og:site_name" content="Odidor " />
                        <meta property="og:title" content={pro.pName} />
                        <meta property="og:type" content="Website" />
                        <meta property="og:description"
                            content={pro.pDes} />
                        <meta property="og:image" content={pro.images[0]} />
                        <meta property="og:url" content={currentURL} />
                    </Helmet>

                    <AppBar position="relative">
                        <Toolbar>
                            <a href="/" style={{ textDecoration: 'none', color: "white" }}>
                                <Typography variant="h6" component="h3" sx={{ ml: 2, flex: 1 }}>
                                    Project Listing
                                </Typography>
                            </a>
                        </Toolbar>
                    </AppBar>

                    <Box sx={{ ...commonStyles }}>
                        <Grid container spacing={2} >

                            <Grid item xs={12} container direction="column" alignItems="center"
                                justify="center">
                                {pro.images[0] && (
                                    <img src={`${pro.images[0]}`} style={{ maxHeight: "500px", maxWidth: "100%" }} />
                                )}
                            </Grid>

                            <Grid item xs={12} md={6} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle1" component="div">
                                            <b>Project:</b> {pro.pName}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Project Type:</b> {pro.pType}
                                        </Typography>
                                        <Typography variant="body2" color="text.primary">
                                            <b>Address:</b> {address?.place_name}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Stage:</b> {pro.pStage}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Est:</b> ${getProEst(pro.pEst)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Contractor:</b> {pro.bName}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <b> Cont. Contact:</b> {pro.bContact}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Cont. Email:</b> {pro.bEmail}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Cont. Web:</b> <a href={`${pro.bWeb}`} target='_blank'>{pro.bWeb}</a>
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="div">
                                            <b>Applicant:</b> {pro.aName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography variant="body2" gutterBottom>
                                            {pro.pDes}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}

        </>
    )
}

export default Project